import { navigate } from 'gatsby';
import React from 'react';
import { Helmet } from "react-helmet"
import Layout from '../components/layout';
import Login from '../components/login';

function IndexPage() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Inloggen - Bruiloft Thom en Cynthia</title>
      </Helmet>
      <div className="mx-auto lg:w-5/6 text-center">
        <h1>Inloggen</h1>
      </div>
      <section className="text-center">
        <Login />
      </section>
    </Layout>
  );
}

export default IndexPage;
